<template>
	<div class="">
		<template v-if="activeIndex == 0">
			<div class="flex">
				<div class="item" :class="isMobile ? '' : 'border'">
					<h2 class="title">项目背景及目标</h2>
					<p class="content indent">
						某保险金融客户是一家提供多元化保险服务的全国性、综合性财产保险公司，以服务海洋经济为切入点，秉承传统，开拓创新，致力于成为一家具有海洋和互联网特色的保险公司。
					</p>
					<p class="content indent">
						随着信息技术的快速发展和企业数字化转型的推进，客户认识到数据中台在整合数据资源、提高数据利用效率和支撑业务决策方面的重要性。因此，决定启动数据中台项目，以构建一个高效、智能、安全的数据管理体系。
					</p>
					<div class="color-title" style="font-size: 14px">通过引入StaRiver RDP帮助客户实现如下的平台目标:</div>
					<p class="example">整合内部各类数据源，形成统一的数据视图，打破数据孤岛，提高数据共享和利用效率；</p>
					<p class="example">为数据分析和挖掘能力提供实时数据基座，支持业务决策和风险管理；</p>
					<p class="example">构建数据服务体系，满足客户内部各部门和外部合作伙伴的数据需求；</p>
				</div>
				<svg-icon v-if="!isMobile" icon-class="success" style="width: 68px; margin: 0 20px 0 40px"></svg-icon>
				<div class="bg1"></div>
			</div>
			<div class="item" style="margin-bottom: 2px">
				<h2 class="color-title">StaRiver RDP建设内容及作用</h2>
				<p class="color-content">数据整合与储存、数据接口开发、数据梳理管理、可视化监控与展示。</p>
			</div>
			<div class="item">
				<p class="content">
					在此项目中StaRiver
					RDP基于自身在数据集成、数据服务、数据管理以及可视化等方面的技术优势，助力客户构建高标准数据平台系统，提升数据整合、加工、存储和管理能力。
				</p>
				<div class="case">
					<h2 class="secend-title">一、数据整合与存储</h2>
					<p class="content">通过数据抽取、清洗、转换和加载（ETL）等技术手段，将内部各类数据源整合到统一的数据仓库中，实现数据的集中存储和管理。</p>
				</div>
				<div class="case">
					<h2 class="secend-title">二、数据接口开发</h2>
					<p class="content">基于接口标准，开发数据接口（API），为内部业务部门和外部合作伙伴提供数据查询、分析和下载等服务。</p>
				</div>
				<div class="case">
					<h2 class="secend-title">三、数据梳理管理</h2>
					<p class="content">
						采用先进的数据虚拟化(Data
						Virtualization)和元数据(MetaData)技术，实现对数据的梳理、元数据目录、数据查看分析的统一管理和应用“一张皮”。并提供权限管理、数据血缘和元数据查看等功能。
					</p>
				</div>
				<div class="case">
					<h2 class="secend-title">四、可视化监控与展示</h2>
					<p class="content">通过数据可视化工具和技术手段，将互联互通结果以图表、报告等形式展示给业务人员和管理层，帮助更好地理解数据流向和状态。</p>
				</div>
			</div>
			<div style="margin-bottom: 20px"></div>
			<div class="item" style="margin-bottom: 2px">
				<h2 class="color-title">StaRiver RDP带来的价值</h2>
				<p class="color-content">提高数据利用率、提升工作效率和数据共享能力、支撑数据预测分析。</p>
			</div>
			<div class="item">
				<p class="content">
					客户数据中台的建设是放眼长远的规划，对整体数字化升级势必产生极大的推动作用，通过StaRiver在数据整合层面的加持对客户在技术和业务上带来更高的附加值。
				</p>
				<div class="case">
					<h2 class="secend-title">一、提高数据利用率</h2>
					<p class="content">
						将客户内部和外部的数据进行有效整合，实现数据的共享和统一管理，避免了数据的重复采集和孤岛现象，提高了数据的利用率。通过数据的采集、整理、计算和分析，为企业提供决策依据，从而实现数据的价值最大化。
					</p>
				</div>
				<div class="case">
					<h2 class="secend-title">二、提升工作效率和数据共享能力</h2>
					<p class="content">
						通过提供统一的数据接口和服务，促进了客户内部各部门之间的数据共享和协同工作。不仅避免了重复无效操作，还提升了工作效率。同时，保证了数据的一致性，使得各部门在基于相同的数据基础上进行决策和分析。
					</p>
				</div>
				<div class="case">
					<h2 class="secend-title">三、支撑数据预测分析</h2>
					<p class="content">
						作为实时数据聚合底座支撑客户进行数据预测分析、跨领域分析、主动分析和实时分析等。有助于及时发现市场趋势和风险因素，为客户的战略规划和风险管理提供有力支持。
					</p>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	components: {},
	props: ["activeIndex", "isMobile"],
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style scoped lang="scss">
.flex {
	display: flex;
	flex-direction: row-reverse;
	background-color: #fff;
	margin-bottom: 20px;
	padding: 20px;
	.border {
		border-bottom: 2px solid #f5f5f5;
		position: relative;
		padding: 0;
		&::after {
			content: "";
			width: 30%;
			height: 4px;
			background-color: #14428f;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}
.item {
	background-color: #fff;
	padding: 20px;
	font-family: PingFang SC, PingFang SC;
	.title {
		font-weight: 800;
		font-size: 20px;
		color: #000000;
		line-height: 28px;
		margin-bottom: 20px;
	}
	.case {
		margin-top: 15px;
		.secend-title {
			font-family: PingFang SC, PingFang SC;
			font-weight: 800;
			font-size: 14px;
			color: #000000;
			line-height: 22px;
		}
	}
	.content {
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		text-align: justify;
		line-height: 22px;
		margin: 10px 0;
	}
	.indent {
		text-indent: 28px;
	}
	.color-title {
		font-weight: 800;
		font-size: 18px;
		color: #14428f;
		line-height: 46px;
	}
	.example {
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		line-height: 24px;
		padding-left: 20px;
		position: relative;
		margin: 10px 0;
		&::before {
			content: "";
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #000;
			position: absolute;
			left: 0;
			top: 7px;
		}
	}
	.color-content {
		font-weight: 500;
		font-size: 14px;
		color: #14428f;
		line-height: 20px;
	}
}
.bg1 {
	background: url("~@/assets/images/success-case1-bg1.png") no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: 19vw;
}
@media (max-width: 900px) {
	.flex {
		flex-direction: column;
		padding: 0;
	}
	.item {
		background-color: #fff;
		padding: 10px;
		font-family: PingFang SC, PingFang SC;
		.title {
			font-weight: 800;
			font-size: 16px;
			color: #000000;
			line-height: 28px;
			margin-bottom: 10px;
		}
		.case {
			margin-top: 15px;
			.secend-title {
				font-family: PingFang SC, PingFang SC;
				font-weight: 800;
				font-size: 14px;
				color: #000000;
				line-height: 22px;
			}
		}
		.content {
			font-weight: 500;
			font-size: 14px;
			color: #000000;
			text-align: justify;
			line-height: 22px;
		}
		.indent {
			text-indent: 28px;
		}
		.color-title {
			font-weight: 800;
			font-size: 16px;
			color: #14428f;
			line-height: 20px;
			margin: 20px 0 10px;
		}
		.example {
			font-weight: 500;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
			padding-left: 20px;
			position: relative;
			&::before {
				content: "";
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #14428f;
				position: absolute;
				left: 0;
				top: 7px;
			}
		}
		.color-content {
			font-weight: 500;
			font-size: 14px;
			color: #14428f;
			line-height: 20px;
		}
	}
	.bg1 {
		background: url("~@/assets/images/success-case1-bg1.png") no-repeat center 100%/100%;
		width: 100%;
		height: 38vw;
		margin-bottom: 0px;
	}
}
</style>
