<template>
	<div style="background-color: #f5f5f5">
		<navComponents />
		<div class="bg1" :style="{ width: screenWidth }"></div>
		<div class="bg1-2">典型案例分享</div>
		<div class="bg2--3" v-if="!isMobile">
			<div class="bg2" :style="{ width: screenWidth }">
				<div class="w">
					<div class="bg2-title">
						<p class="p1">「大型保险公司统一数据平台项目」</p>
						<div class="hengxian"></div>
					</div>
					<div class="bg2-text-box">
						<div class="bg2-left-img">
							<img src="~@/assets/images/success-main-left.png" alt="" />
						</div>
						<div class="bg2-right-img">
							<img src="~@/assets/images/success-main-right.png" alt="" />
						</div>
					</div>
					<div class="bg2-footer-box">
						<div class="footer-title">主要应用场景</div>
						<div class="footer-main">
							<div class="paragraph">
								<p>①</p>
								<p>客户行为等实时数据的标准化补全并分发到各个应用系统；</p>
							</div>
							<div class="paragraph">
								<p>②</p>
								<p>业务系统的实时账户变动与指标变化传输到kafka作为实时数据的计算依据；</p>
							</div>
							<div class="paragraph">
								<p>③</p>
								<p>主数据系统数据和数据仓库数据加载到GreenPlum作为历史数据查询使用。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg3" :style="{ width: screenWidth }">
				<div class="bg3-model"></div>
				<div class="bg3-bg"></div>
				<div class="w">
					<div class="bg3-title">
						<p class="p1">「某金融客户案例」</p>
						<div class="p2">灵活易用的企业级数据订阅与分发总线</div>
						<div class="hengxian"></div>
					</div>
					<div class="bg3-4box">
						<div class="bg3-left">
							<p class="p1">背景</p>
							<p class="p2">公司自2018年开始，将“金融科技发展战略”定为公司战略，以打造一流的数字化金融作为战略目标。</p>
						</div>
						<div class="bg3-right">
							<img src="../assets/images/success-main2.png" alt="" srcset="" />
						</div>
					</div>
					<div class="bg3-middle">
						<div>
							<p>[效率提升] 一个人</p>
							<p>负责数据总线的所有任务</p>
						</div>
						<div>
							<p>[简单易用] 5分钟</p>
							<p>构建实时数据管道快速适应业务变化</p>
						</div>
						<div>
							<p>[性能优异] 秒级实时</p>
							<p>实时报表能让运营及时调整市场投放策略</p>
						</div>
					</div>
					<div class="bg3-footer">
						<div class="footer-title">挑战</div>
						<div class="footer-main">
							<div>
								<i class="icon iconfont icon-a-xingzhuang538"></i>
								<p>
									业务系统作为数据源变化频繁，在原有的方式下需要不断根 据数据源的变化调整、修改任务的配置和脚本，灵活性差，
									且核心系统在夜间完成数据清算后，需工程师手动同步数据， 费时费力。
								</p>
							</div>
							<div>
								<i class="icon iconfont icon-a-xingzhuang538"></i>
								<p>缺少数据链路的监控和预警，依赖下游系统用数时判断数据 是否有异常，缺少过程的监控和预警。</p>
							</div>
							<div>
								<i class="icon iconfont icon-a-xingzhuang538"></i>
								<p>网络环境复杂，生产系统与用数环节跨多个网络分区。</p>
							</div>
							<div>
								<i class="icon iconfont icon-a-xingzhuang538"></i>
								<p>数据同步工程师人员流动性高，知识沉淀与脚本维护难度大。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg2--3 container_box" v-else>
			<swiper ref="mySwiper" :options="swiperOptions">
				<swiper-slide v-for="(item, index) in bg2Data" :key="index">
					<div class="swiper-item" :class="item.active ? 'active' : ''" @click="chooseCur(item, index)">{{ item.title }}</div>
				</swiper-slide>
				<!-- 如果你需要分页器 -->
				<!-- <div class="swiper-pagination" slot="pagination"></div> -->
			</swiper>
			<CaseItemVue :activeIndex="activeIndex" :isMobile="isMobile"></CaseItemVue>
		</div>
		<div class="bg-bottom" v-if="isMobile">
			<div class="bg" v-if="!isMobile">
				<div class="bg2"></div>
			</div>
			<div class="bg2" v-else></div>
		</div>

		<CopyrightComponents />
	</div>
</template>

<script>
//页面引入vue-awesome-swiper 及其样式
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
import CaseItemVue from "./CaseItem.vue";

export default {
	components: { navComponents, CopyrightComponents, swiper, swiperSlide, CaseItemVue },
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
			mobileSrc: `url(${require("@/assets/images/success-navbg-mobile.png")})`,
			pcSrc: `url(${require("@/assets/images/success-navbg.png")})`,
			swiperOptions: {
				// 这里配置Swiper的参数
				// 例如：自动播放、分页等
				// autoplay: {
				// 	delay: 2500,
				// 	disableOnInteraction: false,
				// },
				// pagination: {
				// 	el: ".swiper-pagination",
				// 	clickable: true,
				// },
				// 其他配置...
				slidesPerView: "auto",

				freeMode: true,
				on: {
					//当前swiper-slide点击时触发事件
					click: function (e) {
						let thisWidth = this.clickedSlide.offsetWidth;
						let thisLeft = this.clickedSlide.offsetLeft;
						let offsetX = this.width / 2 - thisLeft - thisWidth / 2;
						//偏移量在1/2视口内时，容器不发生偏移
						if (offsetX > 0) {
							offsetX = 0;
						}
						//offsetX小于容器最大偏移时，让偏移量等于容器最大的偏移量
						else if (offsetX < this.maxTranslate()) {
							offsetX = this.maxTranslate();
						}
						//设置容器的过渡动画
						this.setTransition(300);
						this.setTranslate(offsetX);
						//滚动条位置和长度执行动画
						$(".swiper-wrapper span").animate({ left: thisLeft, width: thisWidth }, 400);
					},
				},
			},
			bg2Data: [
				{
					title: "某保险数据中台项目",
					active: true,
				},
				// {
				// 	title: "太平新加坡的统一数据平台",
				// 	active: false,
				// },
			],
			activeIndex: 0,
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {
		chooseCur(item, index) {
			this.bg2Data.forEach(v => {
				v.active = false;
			});
			item.active = true;
			this.activeIndex = index;
		},
	},
};
</script>

<style scoped lang="scss">
.bg1 {
	height: 550px;
	background-image: url("~@/assets/images/success-navbg.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	color: #fff;
	margin: 0 auto;
	.w {
		width: 1300px;
		margin: 0 auto;
		overflow: hidden;
		p {
			width: 820px;
			height: 78px;
			font-size: 30px;
			font-family: "DFPLiJinHeiW8-GB";
			font-weight: 600;
			color: #ffffff;
			margin-top: 217px;
			line-height: 42px;
		}
	}
}
.bg1-2 {
	height: 60px;
	line-height: 60px;
	text-align: center;
	background-color: #f7f7f7;
	font-size: 20px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #000000;
	&::before {
		content: "";
		display: inline-block;
		width: 60px;
		height: 2px;
		background-color: #000;
		margin-bottom: 5px;
		margin-right: 30px;
	}
	&::after {
		content: "";
		display: inline-block;
		width: 60px;
		height: 2px;
		background-color: #000;
		margin-bottom: 5px;
		margin-left: 30px;
	}
}
.bg2--3 {
	// background: url("~@/assets/images/success-bg1.png") no-repeat center center;
	// background-size: 100% 100%;

	.bg2 {
		.w {
			width: 1300px;
			margin: 0 auto;
			border: 1px solid transparent;
			position: relative;
			.bg2-title {
				width: 400px;
				margin: 50px auto 60px;
				.p1 {
					font-size: 20px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #1b1b1b;
					margin-bottom: 35px;
					text-align: center;
				}
				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 500;
					color: #000000;
					margin-bottom: 30px;
					text-align: center;
				}
				.hengxian {
					width: 60px;
					height: 2px;
					margin: 0 auto;
					background-color: #000;
				}
			}
			.bg2-text-box {
				width: 100%;
				height: 450px;
				margin-top: 40px;
				position: relative;
				background-color: #f8f8f8;
				.p1 {
					font-size: 30px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #d1d1d1;
					position: relative;
					margin-bottom: 55px;
					.p1-zh {
						top: -7px;
						left: 0;
						font-size: 22px;
						font-family: "Microsoft YaHei";
						font-weight: 700;
						color: #000000;
						position: absolute;
					}
				}
				.p2,
				.p3,
				.p4 {
					font-size: 14px;
					color: #000000;
					line-height: 24px;
				}
				.p2,
				.p3 {
					margin-bottom: 25px;
				}
				.bg2-left-img {
					position: absolute;
					width: 827px;
					height: 475px;
					left: -0;
					top: 0;
				}
				.bg2-right-img {
					position: absolute;
					width: 827px;
					height: 475px;
					right: 72px;
					top: 64px;
				}
			}
			.bg2-footer-box {
				height: 250px;
				width: 100%;
				background: url("~@/assets/images/success-bg-min.png") no-repeat center center;
				.footer-title {
					height: 84px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}
				.footer-main {
					display: flex;
					height: 120px;
					justify-content: space-around;
					align-items: center;
					line-height: 25.6px;
					font-size: 14px;
					.paragraph {
						display: flex;
						width: 272px;
						text-align: justify;
						p:first-child {
							margin-right: 12px;
							line-height: 28px;
							font-size: 20px;
							color: #8298bd;
						}
					}
				}
			}
		}
	}
	.bg3 {
		height: 1020px;
		position: relative;
		display: flex;
		justify-content: center;
		.bg3-model {
			height: 1020px;
			width: 100%;
			background-color: #297aff;
			opacity: 0.05;
			position: absolute;
		}
		.bg3-bg {
			height: 420px;
			width: 1300px;
			background: url("~@/assets/images/success-bg2.png") no-repeat center center;
			background-size: 100% 100%;
			position: absolute;
			top: 230px;
		}
		.w {
			width: 1300px;
			// overflow: hidden;
			position: relative;
			.bg3-title {
				width: 300px;
				margin: 20px auto 0;
				padding-top: 60px;
				.p1 {
					font-size: 20px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #1b1b1b;
					margin-bottom: 14px;
					text-align: center;
				}
				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 500;
					color: #000000;
					margin-bottom: 30px;
					text-align: center;
				}
				.hengxian {
					width: 60px;
					height: 2px;
					margin: 0 auto;
					background-color: #000;
				}
			}
			.bg3-4box {
				height: 420px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 60px;
				.bg3-left {
					width: 423px;
					height: 282px;
					background: rgba(41, 122, 255, 0.5);
					border-radius: 15px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 115px 84px;
					.p1 {
						font-size: 22px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						line-height: 16px;
						margin-bottom: 30px;
					}
					.p2 {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						line-height: 25.6px;
					}
				}
				.bg3-right {
					width: 748px;
					height: 282px;
					background: rgba(255, 255, 255, 0.5);
					border-radius: 15px;
				}
			}
			.bg3-middle {
				width: 100%;
				display: flex;
				justify-content: space-around;
				position: absolute;
				bottom: 320px;
				div {
					width: 325px;
					height: 90px;
					border: 2px solid #ffffff;
					background: #e7f0ff;
					border-radius: 5px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 20px;
					p:first-child {
						font-size: 16px;
						font-weight: bold;
						margin-bottom: 15px;
					}
					p:last-child {
						font-size: 14px;
					}
				}
			}
			.bg3-footer {
				margin-top: 52px;
				height: 314px;
				line-height: 26px;
				font-size: 14px;
				background: url("~@/assets/images/success-bg-min2.png") no-repeat center center;
				.footer-title {
					height: 84px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}
				.footer-main {
					display: flex;
					height: 184px;
					justify-content: space-around;
					padding-top: 20px;
					line-height: 25.6px;
					div {
						width: 277px;
						position: relative;
						.icon-a-xingzhuang538 {
							font-size: 12px;
							margin-right: 10px;
							color: #8298bd;
						}
						p {
							position: absolute;
							text-align: justify;
							width: 255px;
							right: 0;
							top: 0;
						}
					}
				}
			}
		}
	}

	.swiper-container {
		margin-bottom: 15px;
	}
	.swiper-slide {
		width: auto !important;
		margin-right: 20px;
		.swiper-item {
			color: #1b1b1b;
			padding: 0px 0 10px;
			font-size: 18px;
			&.active {
				border-bottom: 3px solid #297aff;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.bg-bottom {
	margin-top: 30px;
	padding: 20px;
	width: 100%;
	height: 36vw;
	background-color: #fff;
	.bg {
		width: 1300px;
		margin: 0 auto;
		background: url("~@/assets/images/success-bottom-bg.png") no-repeat center;
		height: 100%;
		padding: 60px 200px;
	}
	.bg2 {
		background: url("~@/assets/images/success-case1-bg2.png") no-repeat center;
		height: 100%;
		background-color: #fff;
		border-radius: 5px;
	}
}

@media (max-width: 900px) {
	.bg1 {
		background-image: url("~@/assets/images/success-navbg-mobile.png");
		height: 375px;
		.w {
			width: 92%;
		}
	}
	.bg2--3 {
		.bg2 {
			.w {
				width: 92%;
			}
		}
		.swiper-container {
			margin-bottom: 15px;
		}
		.swiper-slide {
			width: auto !important;
			margin-right: 20px;
			.swiper-item {
				border-radius: 5px 5px 5px 5px;
				background-color: #fff;
				color: #595e67;
				padding: 7px 10px;
				font-size: 14px;
				color: #ffffff;
				&.active {
					color: #fff;
					background-color: #297aff;
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.bg-bottom {
		height: 72vw;
		.bg2 {
			border-radius: 0;
			background-size: 100%;
		}
	}
}
</style>
